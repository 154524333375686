/** @format */

import React, { useRef, useState } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import homebg from "../images/BGImageMob.png";
import { useLocation } from "react-router-dom";
import ToastMessagee from "../components/ToastMessage";
import CountDown from "../components/CountDown";
import IosShareIcon from "@mui/icons-material/IosShare";
import instaIcon from "../images/insta.png";
import tiktokIcon from "../images/tiktok.png";
import Navbar from "../components/Navbar";
const ReferalPage = () => {
  const location = useLocation();
  const { email, uniqueId, userPoints, rank } = location.state || {};
  console.log("state", email, uniqueId, userPoints, rank);
  const inputRef = useRef(null);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const targetDate = "2025-02-01T00:00:00";
  const { days, hours, minutes, seconds } = CountDown(targetDate);
  const thresholds = [1, 5, 10, 20, 40, 60];
  const nextThreshold = thresholds.find((threshold) => userPoints < threshold);
  const pointsNeeded = nextThreshold ? nextThreshold - userPoints : 0;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://drinkbathwater.com/${uniqueId}`);

    setToastMessage("Link copied to clipboard!");
    setToastType("success");
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 3000);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        // backgroundColor: 'red', // Keep background color
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <img
        src={homebg}
        alt="Background"
        style={{
          width: "100%",
          height: "80%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />

      {toastVisible && (
        <ToastMessagee
          message={toastMessage}
          type={toastType}
          onClose={() => setToastVisible(false)}
        />
      )}
      <Box
        sx={{
          width: "100%",
          position: "relative",
          // overflow: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",

          alignItems: "center",
          height: "100vh",

          paddingTop: { xs: "20px", sm: "40px", md: "60px" },
          paddingBottom: { xs: "20px", sm: "40px", md: "60px" },
        }}
      >
        <Navbar emailAddress={email} rank={`Rank: ${rank}`} />
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={0} md={1} />
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              height: "auto",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              py: { xs: 0, sm: 2, md: 5 },
              mt: { xs: 2, sm: 2, md: 5 },
              px: { xs: 3, sm: 4, md: 5 },
            }}
          >
            <Typography
              variant="h5"
              color="#fff"
              fontFamily="BeVietnam"
              fontWeight="normal"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.2rem",
                  md: "1.5rem",
                  lg: "2rem",
                },
                mr: { xs: 0, sm: 0, md: 1 },
                ml: { xs: 2, sm: 2, md: 0 },
                textAlign: { xs: "left", sm: "center", md: "center" },
              }}
            >
              LAUNCHING
            </Typography>
            <Typography
              variant="h5"
              color="#fff"
              fontFamily="BeVietnam"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.2rem",
                  md: "1.5rem",
                  lg: "2rem",
                },
                mr: { xs: 0, sm: 0, md: 1 },
                ml: { xs: 2, sm: 2, md: 0 },
                textAlign: { xs: "left", sm: "center", md: "center" },
              }}
            >
              FEB 1 AT
            </Typography>
            <Typography
              variant="h5"
              color="#fff"
              fontFamily="BeVietnam"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.2rem",
                  md: "1.5rem",
                  lg: "2rem",
                },
                mr: { xs: 0, sm: 0, md: 1 },
                ml: { xs: 2, sm: 2, md: 0 },
                textAlign: { xs: "left", sm: "center", md: "center" },
                // lineHeight: 1,
                // letterSpacing: 2,
              }}
            >
              9AM PST
            </Typography>
          </Grid>

          <Grid item xs={0} md={1} />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={0} md={1} />
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              px: { xs: 3, sm: 4, md: 5 },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "#fff",
                fontFamily: "beVietnam",
                fontWeight: "normal",
                transformOrigin: "center",
                textAlign: { xs: "left", sm: "center", md: "center" },
                letterSpacing: 1,
                ml: { xs: 2, sm: 0, md: 0 },
                mb: { xs: -1, sm: 0, md: 0 },
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "2.5rem",
                },
                lineHeight: 1,
              }}
            >
              WELCOME TO THE FOUNDERS CLUB
            </Typography>
          </Grid>

          <Grid item xs={0} md={1} />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            my: { xs: 0, sm: 0.7, md: 1 },
            ml: { xs: 0, sm: 0, md: 0 },
          }}
        >
          <Grid item xs={0} md={1} />
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              height: "auto",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              px: { xs: 0, sm: 5, md: 7 },
              my: { xs: -3, sm: 1, md: 2, lg: 2 },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "#fff",
                fontWeight: "700",
                // transform: 'scaleY(2)',
                transformOrigin: "center",
                fontSize: { xs: "3rem", sm: "2rem", md: "3rem" },
                textAlign: { xs: "left", sm: "center", md: "center" },
                width: "100%",
                letterSpacing: 1,
                fontFamily: "Etrusco",
              }}
            >
              Spread the word.
            </Typography>
          </Grid>

          <Grid item xs={0} md={1} />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Grid item xs={0} md={1} />
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              height: "auto",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              px: { xs: 0, sm: 3, md: 5 },
              mr: { xs: 1.5, sm: 0, md: 0 },
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#fff",
                width: { xs: "95%", sm: "80%", md: "75%" },
                fontFamily: "BeVietnamLT",
                fontSize: {
                  xs: "1rem",
                  sm: "1.2rem",
                  md: "1.5rem",
                  lg: "1.8rem",
                },
                lineHeight: 1.2,
                letterSpacing: 1,
                fontWeight: "light",
                textAlign: { xs: "left", sm: "center" },
              }}
            >
              We created Bathwater because you deserve a water brand that is as
              good as you`re told. Get first access and earn rewards by sharing
              this link with your friends.
            </Typography>
          </Grid>

          <Grid item xs={0} md={1} />
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            my: 1,
          }}
        >
          <Grid item xs={0} md={1} />

          <Grid item xs={0} md={1} />
        </Grid>
        <Grid
          className="clipboard"
          container
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            mt: { xs: 5 },
          }}
        >
          <Grid item xs={0} md={1} />
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              height: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              px: { xs: 1, sm: 3, md: 5 },
              mt: { xs: 18, sm: 5, md: -10, lg: 7 },
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                borderRadius: 2,
                px: 1,
              }}
            >
              <Box
                onClick={copyToClipboard}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  backgroundColor: "#00aeef",
                  width: "100%",
                  borderRadius: 3,
                  mt: { xs: 0, sm: 20, md: 20 },
                }}
              >
                <TextField
                  placeholder="Enter your email"
                  value={`https://drinkbathwater.com/${uniqueId}`}
                  inputRef={inputRef}
                  sx={{
                    fontFamily: "AwesomeSerif",
                    backgroundColor: "#00aeef",
                    borderRadius: "10px",
                    mb: 0,
                    mr: { xs: 0, sm: 1, md: 1 },
                    width: { xs: "60%", sm: "90%", md: "100%" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "AwesomeSerif",
                      fontSize: "1.2rem",
                      color: "#00215b",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "white",
                    },
                  }}
                />
                <IosShareIcon sx={{ px: 2, cursor: "pointer" }} />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={0} md={1} />
        </Grid>

        <Box width={"100%"} sx={{ mt: { xs: 5, sm: 10, md: 10 } }}>
          <Box
            sx={{
              zIndex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              fontFamily="beVietnam"
              color="#00215b"
              fontWeight="normal"
              alignItems="center"
              textAlign={{ xs: "left", sm: "center", md: "center" }}
              letterSpacing={2}
              sx={{
                mt: 0,
                mb: 2,
                fontSize: {
                  xs: "1rem",
                  sm: "1.5rem",
                  md: "2rem",
                  lg: "2rem",
                },
                pl: { xs: 6, sm: 6, md: 0 },
                textAlign: { xs: "left", sm: "center", md: "center" },
                width: { xs: "100%", sm: "100%" },
              }}
            >
              YOU HAVE {days} DAYS LEFT
            </Typography>
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Etrusco",
                fontWeight: "bold",
                textAlign: { xs: "left", sm: "center", md: "center" },
                letterSpacing: 1,
                fontSize: { xs: "3rem", sm: "3rem", md: "4rem", lg: "5rem" },
                width: "100%",
                color: "#002244",
                pl: { xs: 6, sm: 6, md: 0 },
              }}
            >
              {userPoints} Friends Joined
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: { xs: 1, sm: 2, md: 3, lg: 4 },
              }}
            >
              <Box display="flex">
                {thresholds.map((threshold, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: { xs: 55, sm: 60, md: 60, lg: 80 },
                      height: { xs: 20, sm: 20, md: 20, lg: 25 },
                      backgroundColor:
                        userPoints >= threshold ? "#BFD730" : "#c3dcf0",
                      borderRight: index < thresholds.length - 1 ? 1 : 0,
                      borderColor: "#00215b",
                      borderTopLeftRadius: index === 0 ? 10 : null,
                      borderBottomLeftRadius: index === 0 ? 10 : null,
                      borderBottomRightRadius:
                        index === thresholds.length - 1 ? 10 : null,
                      borderTopRightRadius:
                        index === thresholds.length - 1 ? 10 : null,
                    }}
                  />
                ))}
              </Box>
              <Box
                display="flex"
                gap={{ xs: 3, sm: 3, md: 4, lg: 5 }}
                mt={{ xs: 0.5, sm: 1, md: 1.5, lg: 2 }}
                justifyContent="center"
              >
                {thresholds.map((threshold, index) => (
                  <Typography
                    key={index}
                    sx={{
                      width: { xs: 30, sm: 30, md: 40, lg: 50 },
                      color: "#00215b",
                      textAlign: "center",
                      fontSize: {
                        xs: "0.6rem",
                        sm: "0.8rem",
                        md: "1rem",
                        lg: "1.2rem",
                      },
                    }}
                    variant="caption"
                  >
                    {threshold}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Typography
              variant="h4"
              fontFamily="beVietnam"
              fontWeight="normal"
              textAlign={{ xs: "left", sm: "center", md: "center" }}
              padding={{ xs: 2, sm: 6, md: 0 }}
              // letterSpacing={2}
              color="#00215b"
              sx={{
                fontSize: {
                  xs: "0.8rem",
                  sm: "1.2rem",
                  md: "2rem",
                  lg: "2rem",
                },
              }}
            >
              <span style={{ fontWeight: "bold", color: "#002244" }}>
                {pointsNeeded} <b>More For Next Reward:</b>
              </span>{" "}
              {userPoints == 0
                ? "Free Sample Of All Flavors"
                : userPoints == 1
                ? "Bathwater Branded Merch"
                : userPoints == 5
                ? "One month of free Bathwater"
                : ""}
            </Typography>
          </Box>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              xs={10}
              sm={10}
              md={10}
              lg={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  py: { xs: 1, sm: 1, md: 3, lg: 3 },
                  px: { xs: 2, sm: 2, md: 3, lg: 3 },
                  backgroundColor: "#BFD730",
                  borderRadius: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: { xs: 1, sm: 1, md: 1, lg: 1 },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#00aeef",
                    borderRadius: 3,
                    py: { xs: 1 },
                    px: { xs: 2 },
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#000",
                      fontFamily: "Etrusco",
                      fontWeight: 700,
                      fontSize: {
                        xs: "1rem",
                        sm: "1.25rem",
                        md: "1.5rem",
                        lg: "1.75rem",
                      },
                    }}
                  >
                    #1 Referrer
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "Etrusco",
                    fontWeight: 700,
                    fontSize: {
                      xs: "1rem",
                      sm: "1.25rem",
                      md: "1.5rem",
                      lg: "1.75rem",
                    },
                  }}
                >
                  Rolling Loud LA Tickets
                </Typography>
              </Box>

              <Box
                sx={{
                  py: { xs: 1, sm: 1, md: 3, lg: 3 },
                  px: { xs: 2, sm: 2, md: 3, lg: 3 },
                  backgroundColor: "#BFD730",
                  borderRadius: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: { xs: 1, sm: 1, md: 1, lg: 1 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#00aeef",
                      borderRadius: 3,
                      py: { xs: 1 },
                      px: { xs: 2 },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: "#000",
                        fontFamily: "Etrusco",
                        fontWeight: 700,
                        fontSize: {
                          xs: "1rem",
                          sm: "1.25rem",
                          md: "1.5rem",
                          lg: "1.75rem",
                        },
                      }}
                    >
                      Top 10
                      <br />
                      Referrer
                    </Typography>
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#000",
                      fontFamily: "Etrusco",

                      letterSpacing: 1,
                      fontSize: {
                        xs: "1rem",
                        sm: "1.25rem",
                        md: "1.5rem",
                        lg: "1.75rem",
                      },
                    }}
                  >
                    {/* (20 Friends) */}
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#000",
                    fontFamily: "Etrusco",
                    fontWeight: 700,
                    fontSize: {
                      xs: "1rem",
                      sm: "1.25rem",
                      md: "1.5rem",
                      lg: "1.75rem",
                    },
                  }}
                >
                  One Year Of Free Bathwater
                </Typography>
              </Box>

              <Box
                sx={{
                  py: { xs: 1, sm: 1, md: 3, lg: 3 },
                  px: { xs: 2, sm: 2, md: 3, lg: 3 },
                  backgroundColor: "#BFD730",
                  borderRadius: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: { xs: 1, sm: 1, md: 1, lg: 1 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: 15,
                      width: 15,
                      border: userPoints == 0 && "2px solid #000",
                      backgroundColor: userPoints >= 1 && "#000",
                      borderRadius: 5,
                    }}
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#000",
                      fontFamily: "BeVietnam",
                      letterSpacing: 1,
                      fontWeight: "normal",
                      mx: { xs: 1, sm: 1, md: 2, lg: 2 },
                      fontSize: {
                        xs: "0.8rem",
                        sm: "1.25rem",
                        md: "1.5rem",
                        lg: "1.75rem",
                      },
                    }}
                  >
                    1 Friend
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "Etrusco",
                    fontWeight: 700,
                    fontSize: {
                      xs: "1rem",
                      sm: "1.25rem",
                      md: "1.5rem",
                      lg: "1.75rem",
                    },
                  }}
                >
                  Free Sample Of Bathwater
                </Typography>
              </Box>

              <Box
                sx={{
                  py: { xs: 1, sm: 1, md: 3, lg: 3 },
                  px: { xs: 2, sm: 2, md: 3, lg: 3 },
                  backgroundColor: "#BFD730",
                  borderRadius: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: { xs: 1, sm: 1, md: 1, lg: 1 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: 15,
                      width: 15,
                      border: userPoints < 5 && "2px solid #000",
                      backgroundColor: userPoints >= 5 && "#000",
                      borderRadius: 5,
                    }}
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#000",
                      fontFamily: "BeVietnam",
                      letterSpacing: 1,
                      fontWeight: "normal",
                      mx: { xs: 1, sm: 1, md: 2, lg: 2 },
                      fontSize: {
                        xs: "0.8rem",
                        sm: "1.25rem",
                        md: "1.5rem",
                        lg: "1.75rem",
                      },
                    }}
                  >
                    5 Friends
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "Etrusco",
                    fontWeight: 700,
                    fontSize: {
                      xs: "1rem",
                      sm: "1.25rem",
                      md: "1.5rem",
                      lg: "1.75rem",
                    },
                  }}
                >
                  Bathwater Branded Merch
                </Typography>
              </Box>
              <Box
                sx={{
                  py: { xs: 1, sm: 1, md: 3, lg: 3 },
                  px: { xs: 2, sm: 2, md: 3, lg: 3 },
                  backgroundColor: "#BFD730",
                  borderRadius: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: { xs: 1, sm: 1, md: 1, lg: 1 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: 15,
                      width: 15,
                      border: userPoints < 10 && "2px solid #000",
                      backgroundColor: userPoints >= 10 && "#000",
                      borderRadius: 10,
                    }}
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#000",
                      fontFamily: "BeVietnam",
                      letterSpacing: 1,
                      fontWeight: "normal",
                      mx: { xs: 1, sm: 1, md: 2, lg: 2 },
                      fontSize: {
                        xs: "0.8rem",
                        sm: "1.25rem",
                        md: "1.5rem",
                        lg: "1.75rem",
                      },
                    }}
                  >
                    10 Friends
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "Etrusco",
                    fontWeight: 700,

                    fontSize: {
                      xs: "1rem",
                      sm: "1.25rem",
                      md: "1.5rem",
                      lg: "1.75rem",
                    },
                  }}
                >
                  One Month Of Free Bathwater
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Box
              sx={{
                width: "20%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box>
                <a href="https://www.instagram.com/">
                  <img src={instaIcon} alt="instaIcon" />
                </a>
              </Box>
              <Box>
                <a href="https://www.tiktok.com/en/">
                  <img src={tiktokIcon} alt="tiktokIcon" />
                </a>
              </Box>
            </Box>
          </Box>
          <Grid
            container
            sx={{
              backgroundColor: "lightgray",
              py: { xs: 1, sm: 2, md: 3, lg: 4 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 5,
            }}
          >
            <Typography
              variant="h5"
              color={"#000"}
              fontFamily={"Etrusco"}
              fontWeight={"bold"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: {
                  xs: "1rem",
                  sm: "1.5rem",
                  md: "1.75rem",
                  lg: "2rem",
                },
              }}
            >
              Need Help? Contact us at :
              <Typography
                variant="h5"
                color={"#00AEEF"}
                fontFamily={"Etrusco"}
                fontWeight={"bold"}
                sx={{
                  fontSize: {
                    xs: "1.25rem",
                    sm: "1.5rem",
                    md: "1.75rem",
                    lg: "2rem",
                  },
                }}
              >
                info@drinkbathwatr.com
              </Typography>
            </Typography>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ReferalPage;
