/** @format */

import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import logo from "../images/logobath.png";

const Navbar = ({ emailAddress, rank }) => {
  return (
    <Box
      sx={{
        width: "100%",
        py: { xs: 3.5, sm: 2, md: 3 },

        mt: { xs: 3, sm: 3, md: 0 },
        backgroundColor: "#BFD730",
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        {/* Left: Logo */}

        {/* Center: Email */}
        <Grid item xs={4} sm={4} display="flex" justifyContent="flex-start">
          <Typography
            variant="h6"
            fontSize="0.8rem"
            sx={{
              textAlign: "center",
              fontWeight: "thin",
              fontFamily: "BeVietnam",
              color: "#fff",
              ml: 2,
              padding: "4px",
              borderRadius: "8px",
              backgroundColor: "#515b15",
              px: 2,
            }}
          >
            {emailAddress}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} display="flex" justifyContent="center">
          <img
            src={logo}
            alt="Logo"
            style={{
              width: "50px",
              height: "auto",
            }}
          />
        </Grid>

        {/* Right: Rank */}
        <Grid item xs={4} sm={4} display="flex" justifyContent="flex-end">
          <Typography
            variant="h6"
            fontSize="1rem"
            sx={{
              textAlign: "center",
              fontWeight: "thin",
              fontFamily: "BeVietnam",
              color: "#fff",
              mr: 2,
              padding: "4px",
              borderRadius: "4px",
              backgroundColor: "#515b15",
              px: 1,
            }}
          >
            {rank}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
